import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useScrollingHeader from "@hooks/useScrollingHeader";
import DensityMediumRoundedIcon from "@mui/icons-material/DensityMediumRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Button, Text, DropDown, CartsView } from "@components";
import defaultUser from "@assets/images/defaultUser.png";
import { Dialog } from "@headlessui/react";
import { imageUrlBuilder } from "@utils";
import "../../App.css";

const AuthenticationRoute = ({ onPressLogin, onPressSignup }) => {
  return (
    <div className="flex flex-row items-center justify-between gap-x-3">
      <Button
        onClick={onPressLogin}
        variant="primary"
        className={`${
          window.location.pathname !== "/signup" ? AuthActive : AuthInactive
        }`}
      >
        {window.location.pathname !== "/signup" ? (
          <Text className={`${AuthText} px-2`}>Log in</Text>
        ) : (
          <Text className={`${InactiveText} px-2`}>Log in</Text>
        )}
      </Button>
      <Button
        onClick={onPressSignup}
        variant="primary"
        className={`${
          window.location.pathname === "/signup" ? AuthActive : AuthInactive
        }`}
      >
        {window.location.pathname === "/signup" ? (
          <Text className={`${AuthText} px-2`}>Sign up</Text>
        ) : (
          <Text className={`${InactiveText} px-2`}>Sign up</Text>
        )}
      </Button>
    </div>
  );
};

const options = [
  {
    index: 0,
    title: "Profile",
    value: "/profile",
  },
  {
    index: 1,
    title: "Settings",
    value: "/settings",
  },
  {
    index: 2,
    title: "Logout",
    value: "/login",
  },
];

const OpenProfile = (props) => {
  const fname = localStorage.getItem("UserData")
    ? JSON.parse(localStorage.getItem("UserData"))?.firstName
    : "User";
  const image = JSON.parse(localStorage.getItem("UserData"))?.image?.url
    ? imageUrlBuilder(JSON.parse(localStorage.getItem("UserData"))?.image?.url)
    : defaultUser;
  return (
    <div className="flex flex-row items-center pr-6 ">
      <div className="w-[60px] h-[60px] bg-[white] rounded-full ml-3">
        <img
          alt=""
          src={image}
          style={{ width: 60, height: 60, color: "white", borderRadius: "50%" }}
        />
      </div>
      <div className="pl-3 text-white">
        <DropDown
          title={fname}
          options={options}
          titleStyle="max-w-[150px] truncate"
        />
      </div>
    </div>
  );
};

const TopNavBar = ({ value, active, path, inActive }) => {
  const pathName = "/" + window.location.pathname.split("/")[1];
  return (
    <>
      <NavLink
        key={value}
        to={path}
        className={`${
          (pathName === "/course" ? "/courses" : pathName) === path
            ? active
            : inActive
        }  ${
          (pathName === "/course" ? "/courses" : pathName) !== path &&
          "header-underline-animation"
        } `}
      >
        {value}
      </NavLink>
    </>
  );
};

export default function Header() {
  const headerClassName = useScrollingHeader();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { menus, logo } = useSelector((state) => state.headerReducer);
  const { userdata } = useSelector((store) => store.userReducer);

  const logoPath =
    logo?.data &&
    imageUrlBuilder(logo?.data?.attributes?.logo?.data?.attributes?.url);

  const headerNav = menus?.data?.filter(
    (val, ind) => val?.attributes?.slug === "header-nav"
  );
  const navlinks = headerNav && headerNav[0]?.attributes?.items?.data;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const onPressLogin = () => navigate("/login");

  const onPressSignup = (e) => {
    e?.stopPropagation();
    navigate("/signup");
  };

  return (
    <header className={headerClassName}>
      <nav className={LargeDeviceContainer} aria-label="Global">
        <div
          className={`flex justify-center xl:w-60 sm:w-60 w-40 ${
            mobileMenuOpen ? "hidden" : "flex"
          }`}
        >
          {/* <NavLink to="/" className="webkitHighlight">
            <img className="img-fluid" src={logoPath} alt="" />
          </NavLink> */}
        </div>
        <div
          className={`xl1:hidden  flex md:flex-row flex-col-reverse items-end justify-center gap-2 ${
            mobileMenuOpen ? "hidden" : "flex"
          }`}
        >
          <CartsView />
          <Button
            style={{ backgroundColor: "rgba(18,16,55,0.30)" }}
            variant="primary"
            className="rounded-md text-white h-10 w-10 webkitHighlight"
            onClick={() => setMobileMenuOpen(true)}
          >
            <DensityMediumRoundedIcon aria-hidden="true" />
          </Button>
        </div>
        <div className={LgNavStyle}>
          {navlinks?.map((item, index) => (
            <TopNavBar
              key={index}
              value={item?.attributes?.title}
              path={item?.attributes?.url}
              active={ActiveTab}
              inActive={InactiveTab}
            />
          ))}
          <CartsView />
        </div>
        <div className="xl1:flex hidden justify-center">
          {userdata && localStorage?.UserToken ? (
            <OpenProfile navigate={navigate} dispatch={dispatch} />
          ) : (
            <AuthenticationRoute
              onPressSignup={onPressSignup}
              onPressLogin={onPressLogin}
            />
          )}
        </div>
      </nav>
      <Dialog
        as="div"
        className="xl1:hidden flex"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-[9]" />
        <Dialog.Panel className={SmDrawer}>
          <div className="flex items-center justify-between xl:mt-16 lg2:mt-16 lg:mt-16 mt-4">
            <div className="flex justify-center webkitHighlight">
              {userdata && localStorage?.UserToken ? (
                <OpenProfile navigate={navigate} dispatch={dispatch} />
              ) : (
                <AuthenticationRoute
                  onPressSignup={onPressSignup}
                  onPressLogin={onPressLogin}
                />
              )}
            </div>
            <Button
              className="-m-2.5 rounded-md p-2.5 text-gray-700 webkitHighlight"
              onClick={() => setMobileMenuOpen(false)}
              variant="primary"
            >
              <ClearRoundedIcon
                className="h-6 w-6 text-[white]"
                aria-hidden="true"
              />
            </Button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navlinks?.map((item, index) => (
                  <TopNavBar
                    key={index}
                    value={item?.attributes?.title}
                    path={item?.attributes?.url}
                    active={MobActive}
                    inActive={MobInactive}
                  />
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}

const LargeDeviceContainer = `
flex 
w-full 
items-center 
justify-between 
xl:px-20 
lg:px-10
px-4
py-4 
gap-x-1 
`;

const LgNavStyle = `
xl1:flex 
flex-1 
hidden 
px-4
xl3:gap-x-16 xl2:gap-x-6 xl1:gap-x-2 gap-x-[3px]
xl3:justify-center xl:justify-between justify-between
`;

const ActiveTab = `
text-[17px] 
font-sans
font-bold 
underline
underline-offset-4
decoration-1
text-white 
self-center
 `;

const InactiveTab = `
font-sans
text-[17px] 
text-white 
self-center`;

const AuthActive = `
lg:px-4 
px-2 
py-[8px] 
bg-primary 
rounded-full 
flex 
items-center 
justify-center`;

const AuthInactive = `
lg:px-4 
px-2 
py-[8px] 
bg-white 
rounded-full 
flex 
items-center 
justify-center`;

const SmDrawer = `
fixed 
inset-y-0 
right-0 
z-10 
w-full 
overflow-y-auto 
bg-blue 
px-6 
py-6 
sm:max-w-sm 
sm:ring-1 sm:ring-gray-900/10`;

const MobActive = `
text-[18px] 
-mx-3 
block 
rounded-lg 
px-3 
py-2 
font-sans
text-base 
font-bold 
leading-7 

text-primary 
border-b-2 
border-b-primary
`;

const MobInactive = `
text-[18px] 
-mx-3 
font-sans
block 
rounded-lg 
px-3 
py-2 
text-base 
font-semibold 
leading-7 
text-white 
`;

const AuthText = `
text-white 
text-[17px] 
font-bold 
font-sans
`;

const InactiveText = `
text-[#004996] 
text-[17px] 
font-bold 
font-sans
`;
