import React, { lazy } from "react";

const ContactUs = lazy(() => import("@pages/contactUs"));
const News = lazy(() => import("@pages/news"));
const NewsDetails = lazy(() => import("@pages/newsDetails"));
const Business = lazy(() => import("@pages/business"));
const DiscoverProGolfer = lazy(() => import("@pages/discoverProGolfer"));
const Support = lazy(() => import("@pages/support"));
const Checkout = lazy(() => import("@pages/checkout"));
const Courses = lazy(() => import("@pages/course"));
const AboutUs = lazy(() => import("@pages/aboutUs"));
const Brands = lazy(() => import("@pages/brands"));
const GolfPerksCourse = lazy(() => import("@pages/golfPerksCourse"));
const WeekendTeeTime = lazy(() => import("@pages/weekendTeeTime"));
const CourseDirectory = lazy(() => import("@pages/courseDirectory"));
const Destination = lazy(() => import("@pages/destination"));
const DestinationDetail = lazy(() => import("@pages/destinationDetail"));
const PrivateClubDirectory = lazy(() => import("@pages/privateClubDirectory"));
const AffiliateProgram = lazy(() => import("@pages/affiliateProgram"));
const Home = lazy(() => import("@pages/home"));
const Search = lazy(() => import("@pages/search"));
const Signin = lazy(() => import("@pages/authentication/signin"));
const ForgotPassword = lazy(() =>
  import("@pages/authentication/forgotPassword")
);
const ResetPassword = lazy(() => import("@pages/authentication/resetPassword"));
const Profile = lazy(() => import("@pages/profile/profile"));
const Settings = lazy(() => import("@pages/profile/settings"));
const Signup = lazy(() => import("@pages/authentication/signup"));

const routes = [
  { exact: true, isPublic: true, path: "/", name: "home", element: <Home /> },
  {
    exact: true,
    isPublic: true,
    path: "/contact-us",
    name: "contact-us",
    element: <ContactUs />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/news",
    name: "news",
    element: <News />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/news/:newsId",
    name: "NewsDetails",
    element: <NewsDetails />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/business",
    name: "Business",
    element: <Business />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/discover-pro-golfers",
    name: "DiscoverProGolfer",
    element: <DiscoverProGolfer />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/golf-perks-course",
    name: "GolfPerksCourse",
    element: <GolfPerksCourse />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/golf-courses",
    name: "GolfCourses",
    element: <Search />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/bestDeals",
    name: "BestDeals",
    element: <Courses />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/private-club-directory",
    name: "PrivateClubDirectory",
    element: <PrivateClubDirectory />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/affiliate-program",
    name: "AffiliateProgram",
    element: <AffiliateProgram />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/support",
    name: "Support",
    element: <Support />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/brands",
    name: "Brands",
    element: <Brands />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/weekendTeeTime",
    name: "WeekendTeeTime",
    element: <WeekendTeeTime />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/course-Directory",
    name: "CourseDirectory",
    element: <CourseDirectory />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/about-us",
    name: "AboutUs",
    element: <AboutUs />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/destination",
    name: "Destination",
    element: <Destination />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/destination/:destinationId",
    name: "DestinationDetail",
    element: <DestinationDetail />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/courses",
    name: "courses",
    element: <Search />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/courses",
    name: "courses",
    element: <Courses />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/course/:coursId",
    name: "courseDetail",
    element: <Courses />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/checkout",
    name: "checkout",
    element: <Checkout />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/search",
    name: "search",
    element: <Search />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/signup",
    name: "signup",
    element: <Signup />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/login",
    name: "login",
    element: <Signin />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/forgot-password",
    name: "forgotPassword",
    element: <ForgotPassword />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/reset-password",
    name: "resetPassword",
    element: <ResetPassword />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/profile",
    name: "profile",
    element: <Profile />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/settings",
    name: "settings",
    element: <Settings />,
  },
];

export default routes;
