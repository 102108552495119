import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useGeolocated } from "react-geolocated";
import ErrorPage from "@pages/errorPage";
import { getMenus, getHeaderData } from "@redux/header/actions";
import {
  upsertRecentSearches,
  upsertRecentViewedNews,
  setCoordinates,
  setDefaultCountryCoordinates,
} from "@redux/user/slice";
import { setUserDetails } from "@redux/authentication/slice";
import { getSetting } from "@redux/settings/actions";
import { Footer } from "@components";
import { Storage } from "@utils";
import routes from "./routes";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { setting } = useSelector((store) => store.settingsReducer);
  const defaultCountry =
    setting?.data?.attributes?.country?.data?.attributes?.name;

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    if (token) {
      navigate(`/reset-password?token=${token}`);
    }
  }, [location]);

  useEffect(() => {
    const initialize = async () => {
      let recentSearches = await Storage.get(Storage.RECENT_SEARCHES);
      let recentViewedNews = await Storage.get(Storage.RECENT_VIEWED_NEWS);
      if (recentSearches) {
        let parsedRecentSearches = JSON.parse(recentSearches);
        dispatch(upsertRecentSearches(parsedRecentSearches));
      }
      if (recentViewedNews) {
        let parsedRecentViewedNews = JSON.parse(recentViewedNews);
        dispatch(upsertRecentViewedNews(parsedRecentViewedNews));
      }
    };
    initialize();
  }, []);

  useEffect(() => {
    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      if (coords.latitude && coords.longitude) {
        axios
          .get(
            `https://api.openweathermap.org/data/2.5/weather?lat=${
              coords.latitude
            }&lon=${
              coords.longitude
            }&cnt=15&units=metric&appid=${"c164ad065360bac38905bb4be3d5dd19"}`
          )
          .then((response) => {
            if (response?.data?.sys?.country) {
              let regionNames = new Intl.DisplayNames(["en"], {
                type: "region",
              });
              let countryName = regionNames.of(response?.data?.sys?.country);
              dispatch(
                setCoordinates({
                  lat: coords.latitude,
                  lng: coords.longitude,
                  country: countryName,
                })
              );
            } else {
              dispatch(
                setCoordinates({
                  lat: coords.latitude,
                  lng: coords.longitude,
                })
              );
            }
          })
          .catch((error) => {
            dispatch(
              setCoordinates({
                lat: coords.latitude,
                lng: coords.longitude,
              })
            );
          });
      }
    }
  }, [isGeolocationAvailable, isGeolocationEnabled, coords]);

  useEffect(() => {
    dispatch(getSetting());
    dispatch(getMenus());
    dispatch(getHeaderData());
    checkUserAuthentication();
  }, []);

  useEffect(() => {
    if (defaultCountry) {
      const fetchGeolocation = async () => {
        try {
          const response = await fetch(
            `https://api.opencagedata.com/geocode/v1/json?q=${defaultCountry}&key=1c9a9c1c266d47acb66de1b1b354c6e5`
          );
          const data = await response.json();
          if (data?.results?.length > 0) {
            dispatch(
              setDefaultCountryCoordinates({
                lat: data?.results[0]?.geometry?.lat,
                lng: data?.results[0]?.geometry?.lng,
              })
            );
          }
        } catch (error) {
          // console.error("Error fetching geolocation data:", error);
        }
      };

      fetchGeolocation();
    }
  }, [defaultCountry]);

  const checkUserAuthentication = async () => {
    let userDetails = await Storage.get(Storage.AUTHENTICATION);
    if (userDetails) {
      let user = JSON.parse(userDetails);
      dispatch(setUserDetails(user));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "100%" }}>
        <Routes>
          {routes.map((item, index) => (
            <Route
              key={"" + index}
              path={item.path}
              exact={item.exact}
              name={item.name}
              element={item.element}
            />
          ))}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
};

export default App;
