import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import tw from "tailwind-styled-components";
import PlaceIcon from "@mui/icons-material/Place";
import FlagIcon from "@mui/icons-material/Flag";
import { Header, SearchInput, Text, Button } from "@components";
import { Storage, updateRecentData, imageUrlBuilder } from "@utils";
import bgImage from "@assets/images/bg.png";
import bgImage2 from "@assets/images/courseBg.png";
import { clubSearchApiList } from "@redux/teetime/actions";
import { upsertRecentSearches } from "@redux/user/slice";
import { updateCustomDate } from "@redux/header/slice";

const styles = {
  bgImageStyle: {
    backgroundImage: `url(${bgImage})`,
    width: "100%",
    height: "667px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  bgImageStyle2: {
    backgroundImage: `url(${bgImage2})`,
    width: "100%",
    height: "545px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
};

const HeaderSection = ({
  headerImage,
  isCompact = true,
  searchInputProps,
  searchMenuProps,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customDate } = useSelector((store) => store.headerReducer);
  const { recentSearches } = useSelector((store) => store.userReducer);
  const { logo } = useSelector((state) => state.headerReducer);
  const { exploreGame, clubSearchList } = useSelector(
    (store) => store.teetimeReducer
  );
  const bgPath =
    logo?.data &&
    imageUrlBuilder(logo?.data?.attributes?.bgImage?.data[0]?.attributes?.url);

  const [state, setState] = useState({
    search: "",
    show: false,
  });

  const changeDate = (e) => dispatch(updateCustomDate({ date: e }));

  const showSuggestionBox = (e) => {
    e.stopPropagation();
    !state.show && setState((prev) => ({ ...prev, show: true }));
  };
  const hideSuggestionBox = () =>
    state.show && setState((prev) => ({ ...prev, show: false }));

  const setSearchData = (e) => {
    let payload = `${e.target.value}`;
    setState((prev) => ({ ...prev, search: payload }));
    dispatch(clubSearchApiList(payload));
  };

  const updateRecentSearches = async (e) => {
    let recentSearchesData = [...recentSearches];
    updateRecentData(recentSearchesData, e);
    dispatch(upsertRecentSearches(recentSearchesData));
    Storage.save(Storage.RECENT_SEARCHES, JSON.stringify(recentSearchesData));
  };

  const onSearch = (e) => {
    if (!e && state.search) {
      alert("Please retry your search entry.");
      return;
    }

    let query = `?date=${dayjs(customDate).toISOString()}`;

    if (e?.club) {
      query = query + "&name=" + e.club;
    }
    if (e?.place) {
      query = query + "&place=" + e.place;
    }
    if (e?.country) {
      query = query + "&country=" + e.country;
    }
    if (e?.slug) {
      query = query + "&slug=" + e.slug;
    } else if (e) {
      updateRecentSearches(e);
    }

    navigate({
      pathname: `/search`,
      search: query,
    });
  };

  const SearchMenu = ({
    suggestionSearch,
    recentSearch,
    clubSearchList,
    search,
    onSearch,
  }) => {
    const { club, location } = clubSearchList;

    return (
      <div className={SearchContainer}>
        {(club.length || location.length) && search.length ? (
          <>
            {club.length ? (
              <>
                <p className="text-[20px] text-[black] font-sans font-semibold">
                  Courses
                </p>
                <div className="flex flex-col">
                  {club?.map((ele, ind) => {
                    return (
                      <Button
                        key={ind}
                        onClick={() => onSearch(ele)}
                        variant="primary"
                      >
                        <p className="py-2 flex ">
                          <FlagIcon className="text-primary mr-1 self-center" />
                          <Text className={SearchSuggestionText}>
                            {ele?.club}
                          </Text>
                        </p>
                      </Button>
                    );
                  })}
                </div>
                <hr className="border-[#dddddddd] pb-5" />
              </>
            ) : null}
            {location.length ? (
              <>
                <p className="text-[18px] font-sans text-black font-semibold">
                  Locations
                </p>
                <div className="flex flex-col">
                  {location?.map((ele, ind) => {
                    return (
                      <Button
                        key={ind}
                        onClick={() => onSearch(ele)}
                        variant="primary"
                      >
                        <p className="py-2 flex">
                          <PlaceIcon className="text-primary mr-1 self-center" />
                          <Text className={SearchSuggestionText}>
                            {`${ele?.place}, ${ele?.country}`}
                          </Text>
                        </p>
                      </Button>
                    );
                  })}
                </div>
              </>
            ) : null}
          </>
        ) : (
          <>
            {search?.length ? (
              <>
                <p className="text-[18px] font-sans text-black font-semibold">
                  No results found. Please try another search.
                </p>
                <hr className="border-[#dddddddd] pb-5" />
              </>
            ) : recentSearch?.length > 0 ? (
              <>
                <p className="text-[18px] text-[black] font-semibold">
                  Recent Searches
                </p>
                <div className="flex flex-col">
                  {recentSearch?.map((ele, ind) => {
                    return (
                      <Button
                        key={ind}
                        onClick={() => onSearch(ele)}
                        variant="primary"
                      >
                        <p className="py-2 flex">
                          {ele?.type == "club" ? (
                            <FlagIcon className="text-primary mr-1 self-center" />
                          ) : (
                            <PlaceIcon className="text-primary mr-1 self-center" />
                          )}
                          <Text className="text-[18px] text-[black] font-semibold hover:underline ">
                            {ele?.type == "club"
                              ? ele?.club
                              : ele?.place
                              ? `${ele?.place}, ${ele?.country}`
                              : ele?.country}
                          </Text>
                        </p>
                      </Button>
                    );
                  })}
                </div>
                <hr className="border-[#dddddddd] pb-5" />
              </>
            ) : null}
            {suggestionSearch?.length ? (
              <>
                <p className="text-[18px] font-sans text-black font-semibold">
                  Quick Searches
                </p>
                <div className="flex flex-row flex-wrap gap-x-2">
                  {suggestionSearch?.map((val, ind) => {
                    const url = imageUrlBuilder(
                      val?.attributes?.image?.data &&
                        val?.attributes?.image?.data?.attributes.url
                    );
                    return (
                      <div className="flex flex-row p-2">
                        <Button
                          onClick={() => {
                            onSearch({ slug: val?.attributes?.slug });
                          }}
                          variant="primary"
                        >
                          <Li className="hover:bg-[#f0efef]">
                            <img src={url} height={30} width={30} />
                            <Text className="font-sans text-[14px] text-[black] pl-2">
                              {val?.attributes?.title}
                            </Text>
                          </Li>
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    );
  };

  return (
    <Container onClick={hideSuggestionBox}>
      <div
        style={{
          "--image-url": `url(${
            headerImage || (isCompact ? bgImage2 : bgPath)
          })`,
        }}
        className={isCompact ? RootHeader2 : RootHeader}
      >
        <Header />
        <SearchInput
          isCompact={isCompact}
          value={state.search}
          selectedDate={customDate}
          setShow={showSuggestionBox}
          changeSelectedDate={changeDate}
          setSearch={setSearchData}
          goToSearch={onSearch}
          {...searchInputProps}
        />
        <div
          className={
            state.show ? "absolute z-[999] left-0 right-0 visible" : "invisible"
          }
        >
          <SearchMenu
            suggestionSearch={exploreGame?.data}
            recentSearch={recentSearches}
            clubSearchList={clubSearchList}
            search={state.search}
            onSearch={onSearch}
            {...searchMenuProps}
          />
        </div>
      </div>
    </Container>
  );
};

const Container = tw.div`
bg-white
`;

const RootHeader = `
pb-10 
lg:pb-20
bg-no-repeat
bg-cover
bg-center
w-full
h-[667px]
max-h-[667px]
bg-[linear-gradient(to_bottom,rgba(0,0,0,0.3)_0%,rgba(0,0,0,0)_33%),var(--image-url)]
`;

const SearchSuggestionText = `
text-black
font-sans
font-semibold
hover:underline
`;

const RootHeader2 = `
pb-10 
lg:pb-20
bg-no-repeat
bg-cover
bg-center
w-full
h-[545px]
max-h-[545px]
bg-[linear-gradient(to_bottom,rgba(0,0,0,0.3)_0%,rgba(0,0,0,0)_33%),var(--image-url)]
`;

const Li = tw.div`
flex-1
flex 
p-2
justify-center
items-center
bg-white
flex
border-[#dddddddd] 
rounded-md 
self-center
`;

const SearchContainer = `
h-[300px] 
flex 
flex-col 
w-[90%] 
md:w-[63%] 
lg:w-[56%] 
xl:w-[38%] 
border-[gray] 
bg-[#ffffffcc] 
rounded-lg 
shadow-[0_0px_20px_#3c7fed90]
mt-5 
px-5 
py-5 
text-white 
m-auto 
overflow-y-scroll 
overflow-x-hidden 
custom-scroll 
transparent`;

export default HeaderSection;
